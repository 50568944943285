import React, { useState, useEffect } from "react";
import * as C from '../../constants';
import './form.css';

export const Form = ({ onResultsChange, currency }) => {
  const [licenceType, setLicenceType] = useState('1');
  const [billingType, setBillingType] = useState('annual');
  const [quantity, setQuantity] = useState(1);
  const [quantityExceedLimit, setQuantityExceedLimit] = useState(false);

  useEffect(() => {
    calculateResults();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenceType, billingType, quantity]);

  const setFxRate = () => {
    const fxRates = {
      "GBP": 1,
      "EUR": C.eurFxRate,
      "USD": C.usdFxRate,
      "AUD": C.audFxRate,
    };
    
    return fxRates[currency];
  }

  const calculateResults = () => {
    let resellerInitialLicencePrice = 0;
    let mfrInitialLicencePrice = 0;
    let resellerInitialDiscount = 0;
    let mfrInitialDiscount = 0; 
    let fxRate = 1;
    let discountRate = 0;

    if (licenceType === '1') {
      resellerInitialLicencePrice = C.rslLicencePrice1Y;
      mfrInitialLicencePrice = C.mfrLicencePrice1Y;
      resellerInitialDiscount = C.rslLicenceDiscount1Y;
      mfrInitialDiscount = C.mfrLicenceDiscount1Y;
    }
    else if (licenceType === '3') {
      resellerInitialLicencePrice = C.rslLicencePrice3Y;
      mfrInitialLicencePrice = C.mfrLicencePrice3Y;
      resellerInitialDiscount = C.rslLicenceDiscount3Y;
      mfrInitialDiscount = C.mfrLicenceDiscount3Y;
    }
    
    // Set FX rate and discount rate
    fxRate = setFxRate(currency);
    
    // Apply incremental discounts by quantity
    if (quantity > 10) {
      let resellerTotalDiscount = (quantity - 10) * resellerInitialDiscount;
      let mfrTotalDiscount = (quantity - 10) * mfrInitialDiscount;

      resellerInitialLicencePrice -= resellerTotalDiscount;
      mfrInitialLicencePrice -= mfrTotalDiscount;
    }  

    if (quantity > 99) {
      setQuantityExceedLimit(true);
    } 
    else {
      setQuantityExceedLimit(false);
    }

    // Set discount %
    const billingTypePremium = (billingType === 'monthly') ? C.monthlyBillingDiscount : 
                                  (billingType === 'annual' && licenceType === '3') ? C.annualBillingDiscount : 0.0;
    
    // Calculate final values
    let resellerCostPerLicence = (resellerInitialLicencePrice * (1 + billingTypePremium)) * fxRate * (1 - discountRate);
    let mfrCostPerLicence = (mfrInitialLicencePrice * (1 + billingTypePremium)) * fxRate * (1 - discountRate);
    let resellerMargin = ((mfrCostPerLicence - resellerCostPerLicence) / mfrCostPerLicence) * 100;
    
    resellerCostPerLicence = resellerCostPerLicence.toFixed(2);
    mfrCostPerLicence = mfrCostPerLicence.toFixed(2);
    resellerMargin = resellerMargin.toFixed(2);

    let resellerTotalPrice = resellerCostPerLicence * quantity;
    let mfrTotalPrice = mfrCostPerLicence * quantity;

    resellerTotalPrice = resellerTotalPrice.toFixed(2);
    mfrTotalPrice = mfrTotalPrice.toFixed(2);

    onResultsChange({
      resellerCostPerLicence,
      resellerMargin,
      resellerTotalPrice,
      mfrTotalPrice,
      mfrCostPerLicence,
    });
  }

  return (
    <>
      <div className="form-container">
        <div>
          <label htmlFor="lt">Term</label>
          <select id="lt" name='licenceType' onChange={(e) => setLicenceType(e.target.value)}>
            <option value={1}>1 Year</option>
            <option value={3}>3 Years</option>
          </select>
        </div>
        <div>
          <label htmlFor="bt">Billing type</label>
          <select id="bt" name='billingType' onChange={(e) => setBillingType(e.target.value)}>
            <option value='annual'>Annual</option>
            <option value='upfront'>Upfront</option>
            <option value='monthly'>Monthly</option>
          </select>
        </div>
        <div>
          <label htmlFor="q">Quantity</label>
          <input id="q" name='quantity' type="number" placeholder="1" step="1" min="1" max="100" onChange={(e) => setQuantity(e.target.value)} />
        </div>
      </div>
      {quantityExceedLimit && (
        <div className="message-container">
          <p className="message">Price indicated is indicative only, please contact sales@nowsignage.com for a bespoke quote.</p>
        </div>
      )}
    </>
  );
}
