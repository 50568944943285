import React from 'react';
import nslogo from '../../images/now-signage-logo.png';
import whiteNslogo from '../../images/white-now-signage-logo.png'
import './mainTitle.css';

export const MainTitle = ({currency}) => {
  return (
    <div>
      <img src={nslogo} alt="NowSignage-Logo"  className='main-logo'/>
      <img src={whiteNslogo} alt="NowSignage-Logo"  className='white-main-logo'/>
      <h1 className='main-title'>Rate card 2024 online calculator</h1>
      <h3 className='main-subtitle'>Platinum VAR partner -{currency}</h3>
    </div>
  );
}
