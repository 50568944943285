import { BrowserRouter, Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { Eur } from '../../pages/Eur';
import { Usd } from '../../pages/Usd';
import { Gbp } from '../../pages/Gbp';
import { Aud } from '../../pages/Aud';
import './router.css';

export const Router = () => {
  return (
    <BrowserRouter>
      <header>
        <div>
          <NavLink to="/gbp" className="navlink" activeClassName="navlink-active">GBP</NavLink>
          <NavLink to="/eur" className="navlink" activeClassName="navlink-active">EUR</NavLink>
          <NavLink to="/usd" className="navlink" activeClassName="navlink-active">USD</NavLink>
          <NavLink to="/aud" className="navlink" activeClassName="navlink-active">AUD</NavLink>
        </div>
      </header>

      <Switch>
        <Route exact path="/gbp" component={Gbp} />
        <Route exact path="/eur" component={Eur} />
        <Route exact path="/usd" component={Usd} />
        <Route exact path="/aud" component={Aud} />
        <Redirect from="*" to="/gbp" />
      </Switch>
    </BrowserRouter>
  );
}
