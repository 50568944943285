import React from "react";
import { MainTitle } from '../MainTitle/MainTitle';
import { ResultsSection } from '../ResultsSection/ResultsSection';
import { Form } from '../Form/Form';
import './main.css';

export const Main = ({currency}) => {
  const [results, setResults] = React.useState({
    resellerMargin: 0,
    resellerCostPerLicence: 0,
    mfrCostPerLicence : 0,
    resellerTotalPrice: 0,
    mfrTotalPrice: 0,
  });

  const handleResultsChange = (newResults) => {
    setResults(newResults);
  };

  return (
    <main>
      <div>
        <MainTitle currency={currency} />
        <Form onResultsChange={handleResultsChange} currency={currency} />
      </div>

      <ResultsSection {...results} currency={currency} />
    </main>
  );
}
