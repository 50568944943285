import { React, useState, useEffect } from 'react';
import { Router } from './components/Router/Router';
import { AccessModal } from './components/AccessModal/AccessModal';
import icons from './images/icons.png';
import ellipse from './images/ellipse.png';

export const App = () => {
  const [accessGranted, setAccessGranted] = useState(false);

  useEffect(() => {
    const storedCodeEntered = localStorage.getItem('codeEntered');
    const expirationTime = localStorage.getItem('codeExpiration');
    
    if (storedCodeEntered && expirationTime && Date.now() < parseInt(expirationTime, 10)) {
      setAccessGranted(true);
    }
  }, []);

  const grantAccess = () => {
    setAccessGranted(true);

    const expirationTime = Date.now() + 30000; 
    localStorage.setItem('codeEntered', 'true');
    localStorage.setItem('codeExpiration', expirationTime.toString());
  };

  return (
    <>
      <img src={ellipse} alt='Ellipse background' className='ellipse-bg' />
      <img src={icons} alt='NS icons background' className='ns-icons-bg' />
      {!accessGranted && (
        <AccessModal
          isOpen={!accessGranted}
          onCorrectCode={grantAccess}
        />
      )}

      {accessGranted && (
        <Router />
      )}
    </>
  );
};
