import React from "react";
import './result.css';

export const Result = ({title, text}) => {
  return (
    <div>
      <h4 className="result-title">{title}</h4>
      <p className="result-text">{text}</p>
    </div>
  );
}
