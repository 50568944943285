import React, { useState } from 'react';
import Modal from 'react-modal';
import './accessModal.css';

Modal.setAppElement("#root");

export const AccessModal = ({ isOpen, onCorrectCode }) => {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const checkCorrectCode = async () => {
    if (code === "123") {
      onCorrectCode(); 
    } else {
      setErrorMessage("Incorrect code. Try again");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      className="modal-base"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }
      }}
    > 
      <h1 className="modal-title">Access code required</h1>
      <div className="modal-content">
        <label className="modal-label" htmlFor="c">Enter access code</label>
        <input
          id="c"
          type="password"
          value={code}
          onChange={handleCodeChange}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              checkCorrectCode();
            }
          }}
          className="modal-input" />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
      <button className="modal-button" onClick={checkCorrectCode}>Submit</button>
    </Modal>
  );
};
